import { useColorMode, useToken } from "@chakra-ui/react";

export function useMapStyles() {
  const { colorMode } = useColorMode();

  const [water, road, landcover, terrain] = useToken("colors", [
    `maps.${colorMode}.water`,
    `maps.${colorMode}.road`,
    `maps.${colorMode}.landcover`,
    `maps.${colorMode}.terrain`,
  ]);

  return [
    { elementType: "geometry", stylers: [{ color: landcover }] },
    { elementType: "labels", stylers: [{ visibility: "off" }] },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: landcover }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: road }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: terrain }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: landcover }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: water }],
    },
  ] satisfies google.maps.MapTypeStyle[];
}
